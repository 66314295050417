<template>
  <!-- if not logined -->
  <v-container class="pa-2" style="max-width: 600px">
    <div class="pt-0 px-1" style="font-size: 14px">
      <a class="text-decoration-none" @click="back_to_home">
        <v-icon>mdi-chevron-left-circle</v-icon>
      </a>
    </div>
    <div class="text-center">
      <v-progress-circular :size="70" :width="7" color="orange" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<!-- if logined -->
<!-- redirect -->

<script>
  import Methods from '@/api/method.js';
  export default {
    name: 'Redirect',

    data: () => ({}),
    async created() {
      console.log('redirect page');
      // if(!this.$store.state.logged_in){
      await this.login();
      // }
      // redirect to the page cached before login
      console.log('login done, before router push');
      console.log('login state', this.$store.state.user.logged_in);
      this.$router.push(this.$store.state.user.login_redirect);
    },
    methods: {
      back_to_home: function() {
        this.$router.push('/');
      },
      login: async function() {
        // if (this.$store.state.logged_in) {
        //   return 0;
        // } else if ("code" in this.$route.query && "state" in this.$route.query) {
        if (this.$store.state.user.login_state == this.$route.query.state) {
          let response = await Methods.LineApiLogin({
            code: this.$route.query.code,
          });
          // console.log(response.data);
          // user_id:user._id,friendship:result.data.friendFlag
          if ('user_id' in response.data) {
            this.$store.commit('user/login_usr', {
              user_id: response.data.user_id,
              friendship: response.data.friendship,
              is_corporation: false,
              is_adult: response.data.is_adult
            });
          } else {
            // this.error = true;
            // this.error_message = "login by Line failed";
            console.log('login by Line failed');
          }
        } else {
          console.log("state don't match");
        }
        // } else {
        //   console.log(this.$route.query)
        //   console.log("not redirected from LINE")
        //   // this.$router.push("/");
        // }
      },
    },
  };
</script>

<style scoped></style>
